import { createTheme } from '@ltvco/refresh-lib/theme';

export const theme = createTheme({
  typography: {
    button: { textTransform: 'none', fontSize: '1rem', fontWeight: 'bold' },
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "'Lato', sans-serif",
    h1: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    caption: {
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
  },
  components: {
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E2E2E2',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: 12,
          },
          div: {
            height: 40,
            backgroundColor: '#fff',
          },
          '& .MuiFormLabel-root': {
            top: -8,
          },
          '& .Mui-focused': {
            top: 0,
          },
          '& .MuiFormLabel-filled': {
            top: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#c2e0e9',
      main: '#24537B',
      dark: '#1e385a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E2F4F9',
      dark: '#c2e0e9',
      light: '#E2E2E2',
      contrastText: '#24537B',
    },
    info: {
      main: '#E2F4F9',
      dark: '#c2e0e9',
      contrastText: '#24537B',
    },
    success: {
      main: '#24537B',
      dark: '#1e385a',
      contrastText: '#fff',
    },
    error: {
      light: '#dc00151f',
      main: '#DC0015',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f8f8f8',
    },
    barChart: {
      main: '#24537B',
      light: '#E2E2E2',
      dark: '#C2E0E9',
      contrastText: '#000',
    },
    lineChart: {
      main: '#24537B',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#000',
    },
    maps: {
      main: '#1F2E64',
      light: '#3388FF',
    },
    progress: {
      main: '#24537B',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#E2E2E2',
    },
    premium: {
      main: '#FCC41C',
    },
    threatDetection: {
      main: '#DC0015',
      light: '#E1FAEA',
      dark: '#81B44C',
      contrastText: '#FFF1F1',
    },
  },
});
