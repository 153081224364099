import { useContext, useEffect, useState } from 'react';
import {
  useReport,
  useSession,
  usePhoneSearchData,
  SearchVolume,
  NuisanceCaller,
  CommentsSection,
  ReportLoading,
  ReportOptions,
  ReportFactory,
  NeighborSpoofing,
  ReportNullState,
  commonTypes,
  ReportMonitor,
  useReportMonitors,
  Monitoring,
  useRemouladeReportSnapshot,
  ReportChangesOverview,
  ReportClaiming,
  DebugMenu,
  PhoneFraudScanSection,
  PotentialSpam,
  PhoneOverview,
} from '@ltvco/refresh-lib/v1';
import type { ReportRouteProps } from './types';
import {
  getPhoneNavLinkData,
  getPhoneNavLinkDataV2,
} from 'navLinkData/phoneNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import questionableCallerGifUrl from '../../images/caller_questionable.gif';
import telemarketerCallerGifUrl from '../../images/caller_telemarketer.gif';
import legitCallerGifUrl from '../../images/caller_legit.gif';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  DateUtil,
  formatPhone,
  isNeighborSpoofed,
  isZeroed,
  unformatPhone,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import {
  AddressHistorySection,
  AssetsSection,
  EducationSection,
  EmailSection,
  JobsSection,
  NotesSection,
  PhoneSection,
  PotentialOwners,
  RelatedReportsSection,
  ReportActionsWithDateUpdated,
  ReportNavigationMenu,
  ReportNavigationMenuProps,
  ReportNavigationMobileMenu,
  ReportRating,
  SocialSection,
} from '@ltvco/refresh-lib/v2';

interface PhoneReportProps extends ReportRouteProps {}

const reportType = 'phone';

export function PhoneReportPage({
  permalink,
  isMonitored = false,
}: PhoneReportProps) {
  const {
    session: { account },
  } = useSession();
  const { logError } = useContext(AppConfig);

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const { data: phoneSearchData } = usePhoneSearchData(
    logError,
    queryResult?.data?.meta?.search_data?.phone_number
  );

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={15} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'phone');

  const showReportClaiming =
    unformatPhone(currentUserInfo?.phone_number || '') === report.phone;

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const neighborSpoofed = isNeighborSpoofed(
    report.data,
    account?.account?.user_info.phone_number
  );

  if (!report) return <ReportLoading menuItems={15} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);

  const {
    ownerName,
    emails,
    educations,
    jobs,
    profiles,
    usernames,
    images,
    phones,
    addresses,
    ownedAssets,
    relatives,
  } = owner;

  const { risk, connectivity, is_valid } = report.supportingPhone || {};
  const {
    fraud_score,
    is_prepaid,
    is_voip,
    is_risky,
    is_recent_abuse,
    is_do_not_call,
  } = risk || {};

  const is_active = connectivity?.is_active;

  const showPhoneFraudScan =
    report.supportingPhone != null && risk != null && connectivity != null;

  const queryParams = new URLSearchParams(search);
  const fallbackPhone = queryParams.get('phone') || '';
  const phoneNumber = formatPhone(report.phone) ?? formatPhone(fallbackPhone);

  const otherPhones = phones.filter(
    (phone: commonTypes.Phone) => phone.number !== fallbackPhone
  );

  const business = report?.data?.rawData?.entities?.businesses[0];
  const businessName = business?.names[0]?.name ?? '';

  let potentialOwnersNumber = 0;

  if (report.data.people.length > 0) {
    potentialOwnersNumber = report.data.people.length;
  } else if (businessName) {
    potentialOwnersNumber = 1;
  }

  const { main_line_type, num_searches, spam_score } = report.data;
  const searchCount = phoneSearchData?.total_volume || num_searches || 0;

  const phoneNavLinkDataV2 = getPhoneNavLinkDataV2(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const phoneNavLinkData = getPhoneNavLinkData(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const comments = report.data.comments || [];
  const carrier = report.data.meta?.search_data?.carrier;
  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  document.title = `${formatPhone(phoneNumber)} - PeopleLooker`;

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Phone Report"
            headerTitle={formatPhone(phoneNumber)}
            menuItems={
              phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={phoneNavLinkData}
            />
          )}

          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              marginX: -1,
              top: 52,
              zIndex: 10,
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Phone"
            dateUpdated={reportUpdateDate}
            reportUpgraded={false}
            shouldFireGAUpsell
          />

          {potentialOwnersNumber === 0 && <PotentialSpam />}
          <PhoneOverview
            reportType={reportType}
            phone={formatPhone(phoneNumber)}
            personName={ownerName || businessName}
            neighborSpoofed={neighborSpoofed}
            phoneOverviewItemsData={{
              comments,
              mainLineType: main_line_type,
              numSearches: searchCount,
              spamScore: spam_score,
              carrier,
              peopleCount: potentialOwnersNumber,
            }}
            Monitoring={MonitoringComponent}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({ showHighConfidenceDataOnly: value });
            }}
            showBlockNumberSection
          />

          <PhoneFraudScanSection
            fraudScore={fraud_score}
            validStatus={is_valid}
            prepaid={is_prepaid}
            voip={is_voip}
            risky={is_risky}
            recentAbuse={is_recent_abuse}
            active={is_active}
            doNotCall={is_do_not_call}
            phoneNumber={phoneNumber}
            showPhoneFraudScan={showPhoneFraudScan}
            showReportCTA
          />
          <NeighborSpoofing phoneReportData={report.data} />
          <SearchVolume searchCount={searchCount} />
          <NuisanceCaller
            reportData={report.data}
            callerSourceMap={{
              telemarketer: telemarketerCallerGifUrl,
              questionable: questionableCallerGifUrl,
              legit: legitCallerGifUrl,
            }}
          />
          <CommentsSection reportData={report.data} permalink={permalink} />

          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
            business={business}
          />
          <PhoneSection
            personName={ownerName || report.data.formatted_number}
            phoneList={otherPhones}
            permalink={permalink}
            alternateTitle="Other Phone Numbers"
          />
          <EmailSection
            personName={ownerName || report.data.formatted_number}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySection
            addresses={addresses}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
          />
          <SocialSection
            personName={ownerName || report.data.formatted_number}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSection
            personName={ownerName || report.data.formatted_number}
            jobs={jobs}
            permalink={permalink}
          />

          <EducationSection
            personName={ownerName || report.data.formatted_number}
            educations={educations}
            permalink={permalink}
          />
          <AssetsSection
            personName={ownerName || report.data.formatted_number}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />

          <NotesSection permalink={permalink} />
          <RelatedReportsSection
            reportType="phone"
            personName={ownerName || report.data.formatted_number}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRating
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Phone Report"
        headerTitle={formatPhone(phoneNumber)}
        menuItems={phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
