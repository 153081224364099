import {
  useAccount,
  EmailVerificationErrorModal,
  EmailVerificationSuccessModal,
} from '@ltvco/refresh-lib/v1';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

export function EmailVerification({ children }: { children: JSX.Element }) {
  // Hooks
  const location = useLocation();
  const { data: account } = useAccount(true);
  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);

  // State
  const [showEmailExpiredModal, setShowEmailExpiredModal] = useState(false);
  const [showEmailSuccessModal, setShowEmailSuccessModal] = useState(false);

  useEffect(() => {
    if (!account) return;

    const isEmailVerified = account?.account.user_info?.verified_email;
    const hasShownSuccessModal =
      account?.account.user_settings?.hide_email_verification_success;

    if (
      !isEmailVerified &&
      statusParam === 'success' &&
      !hasShownSuccessModal
    ) {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailSuccessModal(true);
    } else if (!isEmailVerified && statusParam === 'expired') {
      setStatusParam(undefined, 'replaceIn');
      setShowEmailExpiredModal(true);
    } else if (!isEmailVerified && !showEmailExpiredModal) {
      //setShowEmailPromptModal(true);
    }
  }, [account, statusParam, setStatusParam]);

  return (
    <div>
      <EmailVerificationErrorModal
        open={showEmailExpiredModal}
        onClose={() => setShowEmailExpiredModal(false)}
      />
      <EmailVerificationSuccessModal
        open={showEmailSuccessModal}
        onClose={() => setShowEmailSuccessModal(false)}
      />
      {children}
    </div>
  );
}
