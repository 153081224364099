import { BrandThemeProvider } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { GrowthbookWrapper, useSession } from '@ltvco/refresh-lib/v1';
import { useEffect, useState } from 'react';
import { theme } from 'theme';

export function Growthbook({ children }: { children: JSX.Element }) {
  const {
    session: { account },
  } = useSession();
  const { isMobile } = useScreenSize();
  const deviceType = isMobile ? 'web-mobile' : 'desktop';

  const [customAttributes, setCustomAttributes] = useState<
    Record<string, unknown>
  >({});

  useEffect(() => {
    if (!account) return;

    setCustomAttributes({
      'rf-device-type': deviceType,
      'rf-brand-id': 'peoplelooker-refresh',
    });
  }, [account]);

  return (
    <GrowthbookWrapper
      enableDevMode={true}
      apiHost={'https://gbbv.beenverified.com'}
      clientKey={'sdk-sH9Cly3zfuFM9mdc'}
      decryptionKey={'nZwGIW+xki4yOM6qBBx8Kg=='}
      subscribeToChanges={false}
      customAttributes={customAttributes}
      brandName="people_looker"
    >
      <BrandThemeProvider theme={theme}>{children}</BrandThemeProvider>
    </GrowthbookWrapper>
  );
}
