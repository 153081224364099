import {
  RightRailCard,
  NeedAssistanceSidebar,
  useSnackbar,
  closeSnackbar,
  EmailSettingsCard,
  MonitoredReportsModal,
  useAccount,
  SsoPromoSideBar,
} from '@ltvco/refresh-lib/v1';
import {
  Button,
  Text,
  Grid,
  Link,
  styled,
  CustomIcons,
} from '@ltvco/refresh-lib/theme';
import {
  DateUtil,
  FirstRecordSearch,
  getFirstRecordSearch,
  useScreenSize,
} from '@ltvco/refresh-lib/utils';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { constants } from 'appConstants';
import { SidenavContainer } from '../components/sidenav/SidenavContainer';
import { CookiesStorage } from '@ltvco/refresh-lib';
import { SideBarMigrationCta } from '@ltvco/refresh-lib/v2';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { ReactivatePausedAccountCta } from '@ltvco/refresh-lib/payments';

const CreditCardIcon = styled(CustomIcons.CreditCardIcon)(({ theme }) => ({
  fontSize: '3.75rem',
  padding: theme.spacing(0.9375),
}));

const DollarsAndCoinsIcon = styled(CustomIcons.DollarsAndCoinsIcon)(
  ({ theme }) => ({
    fontSize: '3.75rem',
    padding: theme.spacing(0.9375),
  })
);

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  width: '100%',
  '&&': {
    '& > *': {
      width: '100%',
      maxWidth: '100%',
      border: `1px solid ${theme.palette.secondary.light}`,
      boxShadow: 'none',
      '& > button': {
        width: '95%',
      },
      '& > div button': {
        paddingRight: 0,
      },
    },
  },
}));

export function DashboardParent({
  contactUsHandler,
  handleLogout,
  openConfirmCancelModal,
}: {
  contactUsHandler: () => void;
  handleLogout: () => void;
  openConfirmCancelModal: (downsellTest?: boolean) => void;
}) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { routingUtils } = useContext(AppConfig);
  const cookieStorage = new CookiesStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenSize();

  const dateUtil = new DateUtil();
  const currentDate = new Date();

  // State
  const [monitoredReportsOpen, setMonitoredReportsOpen] = useState(false);

  const { data: account } = useAccount(true);

  function closeMonitoredReportsModal() {
    setMonitoredReportsOpen(false);
  }

  useEffect(() => {
    const firstRecordSearchData = getFirstRecordSearch();

    if (firstRecordSearchData) {
      const typesForReportRoute = ['phone', 'email', 'username', 'property'];
      if (typesForReportRoute.includes(firstRecordSearchData.searchType)) {
        handleRedirectToReportRoute(firstRecordSearchData);
      }

      const typesForSearchRoute = [
        'person',
        'for_sale',
        'pre_foreclosures',
        'unclaimed_money',
      ];
      if (typesForSearchRoute.includes(firstRecordSearchData.searchType)) {
        handleRedirectToSearchRoute(firstRecordSearchData);
      }

      cookieStorage.removeValue('first_record_search');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('pausedAccountSnackbarId')) {
      try {
        closeSnackbar(
          JSON.parse(localStorage.getItem('pausedAccountSnackbarId') || '')
        );
      } catch (e) {
        console.log('error', e);
      }
    }
    handleSiteNotifications();
    handleReactivationForPausedAccountsBanner();
  }, [account]);

  function handleRedirectToSearchRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    if (
      firstRecordSearchData.last_name &&
      (firstRecordSearchData.searchType === 'person' ||
        firstRecordSearchData.searchType === 'unclaimed_money')
    ) {
      navigate(
        routingUtils.searchPersonUrl({
          fName: firstRecordSearchData.first_name,
          mName: firstRecordSearchData.middle_name,
          lName: firstRecordSearchData.last_name,
          state: firstRecordSearchData.state,
          city: firstRecordSearchData.city,
          age: firstRecordSearchData.age,
        })
      );
    } else if (
      firstRecordSearchData.searchType === 'for_sale' ||
      firstRecordSearchData.searchType === 'pre_foreclosures'
    ) {
      navigate(
        routingUtils.searchPropertyListingsUrl({
          city: firstRecordSearchData.city,
          state: firstRecordSearchData.state,
          zip5: firstRecordSearchData.zip_code,
          search_type:
            firstRecordSearchData?.searchType === 'pre_foreclosures'
              ? 'pre_foreclosures'
              : undefined,
        })
      );
    }
  }

  function handleRedirectToReportRoute(
    firstRecordSearchData: FirstRecordSearch
  ) {
    let queryParams = '';

    if (firstRecordSearchData.searchType === 'phone') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'username') {
      queryParams = `username=${firstRecordSearchData.username}`;
    }
    if (firstRecordSearchData.searchType === 'property') {
      queryParams = `address=${firstRecordSearchData.address}&city=${firstRecordSearchData.city}&state=${firstRecordSearchData.state}&zipcode=${firstRecordSearchData.zip_code}`;
    }

    const navigateTo = `/report/${firstRecordSearchData.searchType}?${queryParams}`;
    navigate(navigateTo);
    // Setting this cookie for redirecting properly on the Onboarding Questions
    // cookieStorage.setValue('first_report_navigate', navigateTo);
  }

  function handleReactivationForPausedAccountsBanner() {
    if (!account) return;

    const isInPausedStatus = account?.meta?.subscription_state === 'paused';
    if (!isInPausedStatus) return;

    const today = currentDate.toISOString();
    const expirationDate = account?.account.subscription_info?.normalize_date;

    if (!expirationDate) return;

    const isAfterCurrentDate = dateUtil.isAfter(today, expirationDate);

    const expiredMessage = (
      <Text>
        <b>
          Your account expired on{' '}
          {dateUtil.parseDateFromString(
            account?.account.subscription_info?.normalize_date,
            'M/d/y'
          )}{' '}
          . Reactivate to regain access.
        </b>
      </Text>
    );

    const cancelledMessage = (
      <Text>
        <b>
          Your account with member ID {account?.account.user_info.user_code} has
          been cancelled.
        </b>{' '}
        <br /> You have until{' '}
        {dateUtil.parseDateFromString(
          account?.account.subscription_info?.normalize_date,
          'M/d/y'
        )}{' '}
        to continue running reports. Please reactivate now to maintain access.
        Visit{' '}
        <Link target="_blank" href="https://peoplelooker.com/rf/reactivation">
          here
        </Link>{' '}
        to reactivate.
      </Text>
    );

    const message = isAfterCurrentDate ? expiredMessage : cancelledMessage;

    const pausedAccountSnackbarId = enqueueSnackbar(message, {
      variant: 'info',
      persist: true,
      action: getCloseButton(() => {
        closeSnackbar(pausedAccountSnackbarId);
      }),
    });

    localStorage.setItem(
      'pausedAccountSnackbarId',
      JSON.stringify(pausedAccountSnackbarId)
    );
  }

  function getCloseButton(onClick: () => void) {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        sx={(theme) => ({
          fontsize: theme.spacing(0.75),
          maxHeight: '40px',
          width: '125px',
          color: `${theme.palette.success.contrastText} !important`,
          backgroundColor: `${theme.palette.success.main} !important`,
        })}
      >
        Close
      </Button>
    );
  }

  function handleSiteNotifications() {
    if (!account) return;
    const siteNotification = account?.meta?.notification;
    if (!siteNotification) return;
    const siteNotificationSnackbarId = enqueueSnackbar(
      <Text>
        <b>{siteNotification.title}</b> <br /> {siteNotification.message}
      </Text>,
      {
        variant: 'info',
        persist: true,
        action: (
          <Button
            onClick={() => {
              closeSnackbar(siteNotificationSnackbarId);
            }}
            variant="contained"
            sx={(theme) => ({
              fontsize: theme.spacing(0.75),
              maxHeight: '40px',
              width: '125px',
              color: `${theme.palette.success.contrastText} !important`,
              backgroundColor: `${theme.palette.success.main} !important`,
            })}
          >
            Close
          </Button>
        ),
      }
    );
  }

  const isIdentityHub = location.pathname.includes('/dashboard/identity-hub');
  const showRightRail = !isIdentityHub;

  const RightRailComponent = (
    <Grid
      item
      container
      xs={12}
      md={3}
      lg={3}
      spacing={1}
      direction="column"
      marginTop={2}
      maxWidth={{ xs: '100%', md: '299px' }}
    >
      {location.pathname.includes('/dashboard/billing') && (
        <Grid
          item
          width={'100%'}
          order={{ xs: 6, md: 1 }}
          className="needs-assistance"
        >
          <NeedAssistanceSidebar
            logout={handleLogout}
            supportImgUrl={constants.images.img24hr}
            contactUsHandler={contactUsHandler}
            openConfirmCancelModal={openConfirmCancelModal}
          />
        </Grid>
      )}
      {location.pathname.includes('/dashboard/monitoring') && (
        <Grid item width={'100%'} order={2}>
          <EmailSettingsCard
            currentInstantAlertsSetting={
              account?.account?.alert_me.settings.instant_enabled || false
            }
            currentDigestAlertsSetting={
              account?.account?.alert_me.settings.digest_enabled || false
            }
            handleMonitoredReports={() => setMonitoredReportsOpen(true)}
          />
          <MonitoredReportsModal
            isOpen={monitoredReportsOpen}
            onClose={() => closeMonitoredReportsModal()}
          />
        </Grid>
      )}
      {!isIdentityHub && (
        <>
          {isMobile && (
            <Grid item width={'100%'} order={2} key="mobile-sso-promo">
              <StyledContainer>
                <SsoPromoSideBar
                  imagePath={constants.images.imgSidePromoBanner}
                />
                <SideBarMigrationCta />
              </StyledContainer>
            </Grid>
          )}
          <Grid item width={'100%'} order={3}>
            <RightRailCard
              icon={<DollarsAndCoinsIcon />}
              title="Check for Unclaimed Money"
              subtitle="Included with your membership is a state government search of billions in unclaimed money"
              body="Find out if unclaimed money is owed to you or anyone you know!"
              buttonText="Unclaimed Money Search"
              tagText="Bonus Feature"
              showTag={true}
              tagOnLeft={false}
              onButtonClick={() => navigate('unclaimed-money')}
            />
          </Grid>
          <Grid item width={'100%'} order={4}>
            <RightRailCard
              icon={<CreditCardIcon />}
              title="Dark Web Scan"
              subtitle="Millions of online accounts are compromised each year"
              body="Search using your email address to see if you may have been compromised on the dark web"
              buttonText="Scan Now"
              tagText="New"
              showTag={true}
              tagOnLeft={false}
              onButtonClick={() => navigate('dark-web')}
            />
          </Grid>
        </>
      )}
      {account?.meta?.subscription_state === 'paused' && (
        <Grid item order={5}>
          <ReactivatePausedAccountCta />
        </Grid>
      )}
    </Grid>
  );

  const RightRail = showRightRail ? RightRailComponent : <></>;

  return (
    <SidenavContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid
          item
          xs={12}
          md={showRightRail ? 8 : 12}
          lg={showRightRail ? 8 : 12}
          maxWidth={{
            xs: '100%',
            md: showRightRail ? '911px !important' : '100%',
          }}
        >
          <Outlet />
        </Grid>
        {RightRail}
      </Grid>
    </SidenavContainer>
  );
}
