import {
  PaymentsSelectionModal,
  Plan,
  ProcessingSpinnerModal,
  RadioPaymentsModal,
  TosModal,
  UpgradeFailureModal,
  UpgradePlan,
  UpgradeSuccessModal,
  enqueueSnackbar,
  filterActivePaymentMethod,
  useAccount,
  useGetPlans,
  useGetPaymentMethod,
  useProcessCard,
  useProcessPayPal,
  useProcessBraintree,
  useSession,
  useSubmitLegal,
  useGetPaymentType as getPaymentType,
} from '@ltvco/refresh-lib/v1';
import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, styled } from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import {
  CurrentPlan,
  getBillingFrequency,
  getPlanTitleAndDescription,
  getPlans,
  planCostPerReport,
} from './plans';
import { useQueryParams } from 'utils/useQueryParams';

const UpgradePlanContent = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3.5, 2),
  marginTop: 'max(0px,calc(40px - 1vw))',
  paddingLeft: 0,
  paddingRight: 0,
}));

enum State {
  None,
  SelectPayment,
  AddPayment,
  ProcessingPayment,
  Success,
  Error,
}

interface UpgradePlanPageProps {
  contactUsHandler: () => void;
}

export const UpgradePlanPage = ({ contactUsHandler }: UpgradePlanPageProps) => {
  const { refetch: refetchAccount } = useAccount(true);
  const {
    accountLoaded,
    isStaff,
    isLimitedUser,
    isCancelledSubscription,
    planAmount: amount,
    reportLimit: limit,
    renewalPeriod,
  } = useLimitedPlanInfo();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const [upgradeState, setUpgradeState] = useState(State.None);
  const isState = (state: State) => upgradeState === state;

  const customPlanId = useQueryParams().get('plan_id');
  const brandPlans = useGetPlans();
  const title = isStaff ? 'Staff' : getPlanTitleAndDescription(limit).title;
  const currentPlan: CurrentPlan = useMemo(() => {
    return {
      title,
      amount,
      renewalPeriod,
      limit,
      costPerReport: planCostPerReport({ amount, renewalPeriod, limit }),
    };
  }, [amount, limit, renewalPeriod, title]);

  useEffect(() => {
    if (!accountLoaded) return;

    if (brandPlans?.data?.subscription?.upgrade_options) {
      setPlans(
        getPlans(
          brandPlans.data.subscription.upgrade_options,
          currentPlan,
          Number(customPlanId)
        )
      );
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [
    accountLoaded,
    currentPlan,
    brandPlans?.data?.subscription?.upgrade_options,
    customPlanId,
  ]);

  const showDefaultError = () => {
    enqueueSnackbar(
      'Something went wrong, please try again or contact customer service',
      { variant: 'error' }
    );
  };

  const onSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan);
    setBillingFrecuency(getBillingFrequency(plan.renewalPeriod));
    setBilledPrice(`$${plan.amount}`);
    setOpenTosModal(true);
  };

  // TOS

  const updateLegalQuery = useSubmitLegal();
  const [openTosModal, setOpenTosModal] = useState(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [billingFrecuency, setBillingFrecuency] = useState('monthly');
  const [billedPrice, setBilledPrice] = useState('');

  const onCloseTos = () => setOpenTosModal(false);
  const onAcceptTos = () => {
    setProcessingPayment(true);
    updateLegalQuery.mutate(undefined, {
      onSuccess: () => upgradePlan(),
      onError: () => showDefaultError(),
      onSettled: () => onCloseTos(),
    });
  };

  // Payment

  const paymentMethodQuery = useGetPaymentMethod();
  const processCardQuery = useProcessCard();
  const processPayPalQuery = useProcessPayPal();
  const processBraintreeQuery = useProcessBraintree();
  const [upgradeErrors, setUpgradeErrors] = useState<string[]>([]);
  const [hadPaymentErrors, setHadPaymentErrors] = useState(false);

  const upgradePlan = async () => {
    const activePaymentQueryRefetched = await paymentMethodQuery.refetch();
    const activePayment = filterActivePaymentMethod(
      activePaymentQueryRefetched?.data
    );

    if (!selectedPlan || !activePayment) return;

    setUpgradeState(State.ProcessingPayment);

    const plan = { unique_key: selectedPlan.key };
    const queryOptions: any = {
      onSuccess: () => {
        refetchAccount();
        setUpgradeState(State.Success);
      },
      onError(error: Error) {
        let cause = error.cause as string[];
        const stringifiedErrors = cause?.join(', ');

        if (stringifiedErrors === 'Unknown Checkout Failure Reason') {
          cause = [
            'Your payment failed, please try again or choose a different payment method.',
          ];
        }

        setUpgradeErrors(cause);
        setUpgradeState(State.Error);
      },
    };

    interface MutationFunctions {
      paypal: typeof processPayPalQuery;
      braintree: typeof processBraintreeQuery;
      card: typeof processCardQuery;
    }

    const mutationFunctions: MutationFunctions = {
      paypal: processPayPalQuery,
      braintree: processBraintreeQuery,
      card: processCardQuery,
    };

    const paymentType: 'card' | 'braintree' | 'paypal' =
      getPaymentType(activePayment);

    const mutationFunction = mutationFunctions[paymentType] || processCardQuery;

    const mutationParams = {
      plan,
      ...((paymentType === 'paypal' && {
        paypalId: activePayment.id,
      }) as { paypalId: number }),
      ...((paymentType === 'card' && {
        cardId: activePayment.id,
      }) as {
        cardId: number;
      }),
      ...((paymentType === 'braintree' && {
        braintreeId: activePayment.id,
      }) as { braintreeId: number }),
      source_of_payment: 'manual_upgrade_attempt',
    };
    setProcessingPayment(false);
    mutationFunction.mutate(mutationParams, queryOptions);
  };

  const hasPlans = plans.length > 0;

  return (
    <UpgradePlanContent container={hasPlans}>
      {isLoading && <CircularProgress sx={{ my: 30 }}></CircularProgress>}

      {!isLoading && (
        <>
          <UpgradePlan
            currentPlan={{
              title: currentPlan.title,
              limit: isLimitedUser ? currentPlan.limit : 'unlimited',
              costPerReport: currentPlan.costPerReport,
              canceled: isCancelledSubscription,
            }}
            plans={plans}
            onSelectPlan={onSelectPlan}
            onContactUs={contactUsHandler}
          />

          <TosModal
            open={openTosModal}
            showCheckbox={true}
            billingFrecuency={billingFrecuency}
            billedPrice={billedPrice}
            cta={{
              text: 'Accept and Upgrade',
              extraWide: true,
              processingPayment,
            }}
            showPaymentMethod={true}
            acceptTos={onAcceptTos}
            onChangePaymentMethod={() => setUpgradeState(State.SelectPayment)}
            onClose={onCloseTos}
          />

          <PaymentsSelectionModal
            open={isState(State.SelectPayment)}
            cta="Use This Payment Method"
            onAddPaymentMethod={() => setUpgradeState(State.AddPayment)}
            onSelectedPaymentMethod={() => {
              if (hadPaymentErrors) {
                setHadPaymentErrors(false);
                upgradePlan();
              } else {
                setUpgradeState(State.None);
              }
            }}
            onClose={() => {
              if (hadPaymentErrors) {
                setHadPaymentErrors(false);
                setUpgradeState(State.Error);
              } else {
                setUpgradeState(State.None);
              }
            }}
          />

          <RadioPaymentsModal
            isOpen={isState(State.AddPayment)}
            onCloseHandle={() => setUpgradeState(State.None)}
          />

          <ProcessingSpinnerModal open={isState(State.ProcessingPayment)} />

          <UpgradeSuccessModal
            open={isState(State.Success)}
            onAccept={() => {
              setUpgradeState(State.None);

              navigate('/dashboard');
            }}
          />

          <UpgradeFailureModal
            open={isState(State.Error)}
            errors={upgradeErrors}
            onTryAgain={() => upgradePlan()}
            onChangeCard={() => {
              setHadPaymentErrors(true);
              setUpgradeState(State.SelectPayment);
            }}
            onClose={() => {
              setUpgradeState(State.None);
            }}
          />
        </>
      )}
    </UpgradePlanContent>
  );
};
