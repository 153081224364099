import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CTACard,
  DashboardRecentReports,
  useRecentReports,
  RecentReportSerialized,
  MyRecentReports,
  useAccount,
  DashboardOnboardingContainer,
  useDashboardOnboarding,
} from '@ltvco/refresh-lib/v1';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Text, Grid, CustomIcons } from '@ltvco/refresh-lib/theme';
import { useQueryParams } from 'use-query-params';
import { generateQueryConfig, stringifyParams } from '../../components/BBQ';
import { publish } from '@ltvco/refresh-lib';

const queryConfig = generateQueryConfig();

export function DashboardPage() {
  useEffect(() => {
    document.title = 'Dashboard - Peoplelooker';
  }, []);

  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const [queryParams] = useQueryParams(queryConfig);
  const filteredParams = stringifyParams(queryParams);
  const { data: queryData } = useRecentReports(filteredParams);
  const reports = (queryData?.reports as RecentReportSerialized[]) || [];
  const { hideOnboardingChecklist, onboardingSteps } = useDashboardOnboarding();

  const { data: account } = useAccount(true);

  return (
    <div>
      <h1>Welcome, {account?.account.user_info.first_name}</h1>
      <DashboardOnboardingContainer
        hideDashboardOnboarding={hideOnboardingChecklist}
        dashboardOnboardingSteps={onboardingSteps || []}
      />
      {Boolean(!reports.length) ? null : (
        <>
          <MyRecentReports />
          <DashboardRecentReports reportList={reports} />
        </>
      )}
      <Grid direction={'column'} container>
        <Grid item>
          <Text variant="h3" mb={3} mt={2}>
            Explore New Features
          </Text>
        </Grid>
        <Grid container item direction="column">
          <Grid
            container
            item
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={isMobile ? 'column' : 'row'}
          >
            <Grid item width={isMobile ? '100%' : '49%'}>
              <CTACard
                onClick={() => navigate('/dashboard/identity-hub')}
                body="Monitor your online identity to get notified if changes are detected"
                image={
                  <CustomIcons.PurpleShield sx={{ fontSize: '3.125rem' }} />
                }
                title="Identity Hub"
                buttonLabel="Monitor now"
              />
            </Grid>
            <Grid item width={isMobile ? '100%' : '49%'}>
              <CTACard
                onClick={() => publish('openPersonSearch')}
                body="Find arrest records, contact information and more"
                image={
                  <CustomIcons.SelfSearchBV sx={{ fontSize: '3.125rem' }} />
                }
                title="Search Background Reports"
                buttonLabel="Search by name now"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={isMobile ? 'column' : 'row'}
          >
            <Grid item width={isMobile ? '100%' : '49%'}>
              <CTACard
                onClick={() => navigate('/dashboard/dark-web')}
                body="See if the email you signed up with may have been leaked in a data breach"
                image={
                  <CustomIcons.CreditCardIcon sx={{ fontSize: '3.125rem' }} />
                }
                title="Dark Web Scan"
                buttonLabel="Scan now"
              />
            </Grid>
            <Grid item width={isMobile ? '100%' : '49%'}>
              <CTACard
                onClick={() => navigate('/dashboard/unclaimed-money')}
                body="Did you know 1 in 10 Americans have unclaimed money?"
                image={
                  <CustomIcons.DollarsAndCoinsIcon
                    sx={{ fontSize: '3.125rem' }}
                  />
                }
                title="Unclaimed Money"
                buttonLabel="Check unclaimed money"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
