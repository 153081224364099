import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useSearchObituary,
  PersonSearchLoading,
  ObituaryResultsCard,
  ObituarySearchForm,
} from '@ltvco/refresh-lib/v1';
import {
  Stack,
  Text,
  Grid,
  Box,
  styled,
  Card,
  Button,
} from '@ltvco/refresh-lib/theme';
import { ageFromDob, useScreenSize } from '@ltvco/refresh-lib/utils';
import { useSortedObitResults } from '@ltvco/refresh-lib/hooks';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Close, Tune } from '@mui/icons-material';

const StickyContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 60,
  width: '100%',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: theme.spacing(10),
    zIndex: 1,
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
}));

export function SearchObituaryResults() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Search - PeopleLooker';
  }, []);

  const searchData = useMemo(() => {
    return {
      name: searchParams.get('name') || '',
      dob: searchParams.get('dob'),
      dod: searchParams.get('dod'),
      location: searchParams.get('location'),
    };
  }, [searchParams]);

  const { data, isFetched } = useSearchObituary({
    obituarySearchParams: searchData,
  });

  const sortedObits = useSortedObitResults(data, searchData);

  const { trackEventGA4 } = useContext(AppConfig);

  const { isMedium } = useScreenSize();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  function trackGA4() {
    trackEventGA4({
      event_name: 'report_search',
      report_type: 'obituary',
      search_by: 'obituary',
      search_from: `obituary_teaser`,
    });
  }

  if (isFetched && !data?.length) {
    let fname,
      mn,
      ln,
      age = '';
    const name = searchData.name.split(' ');
    if (name.length === 2) {
      fname = name[0];
      mn = '';
      ln = name[1];
    } else if (name.length === 3) {
      fname = name[0];
      mn = name[1];
      ln = name[2];
    }

    if (searchData.dob) {
      const dobArray = searchData.dob.split('-');
      dobArray.splice(1, 0, '01');
      const dobString = dobArray.join('-');
      age = ageFromDob(dobString).toString();
    }
    navigate(
      `/search/person?age=${age}&city=&fname=${fname}&ln=${ln}&mn=${mn}&state=All&redirected_from_obit=true`
    );
  }

  const resultsTitle = `${data?.length} results`;

  return (
    <>
      {!sortedObits.length ? (
        <PersonSearchLoading />
      ) : (
        <Stack sx={{ minHeight: 800, position: 'relative' }}>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            sx={{ mt: 3 }}
          >
            <Grid item xs={12} md={3}>
              <StickyContainer>
                <Card
                  sx={{ maxHeight: isMedium ? '450px' : '', overflow: 'auto' }}
                >
                  {isMedium && isCollapsed && (
                    <Button
                      id="filterToggle"
                      type="button"
                      name="filterToggle"
                      variant="outlined"
                      onClick={handleCollapseToggle}
                      fullWidth
                      color="success"
                    >
                      <Text variant="h3" sx={{ mb: 1 }}>
                        <Tune sx={{ m: 0.75, mb: -0.75 }} />
                        Filter results
                      </Text>
                    </Button>
                  )}
                  {isMedium && !isCollapsed && (
                    <Button
                      id="filterToggle"
                      type="button"
                      name="filterToggle"
                      onClick={handleCollapseToggle}
                      sx={{
                        float: 'right',
                        '&:hover': {
                          background: 'transparent',
                        },
                      }}
                      color="success"
                    >
                      <Text variant="body1" sx={{ mt: -2 }}>
                        <Close sx={{}} /> <br />
                        Close filter
                      </Text>
                    </Button>
                  )}
                  {(!isMedium || (isMedium && !isCollapsed)) && (
                    <React.Fragment>
                      <Text variant="h2">Search another obituary</Text>
                      <ObituarySearchForm
                        miniView={true}
                        defaults={searchParams}
                        trackEvent={trackGA4}
                      />
                    </React.Fragment>
                  )}
                </Card>
              </StickyContainer>
            </Grid>
            <Grid item xs={12} md={8} sx={{ mt: { xs: 8.75, md: 0 } }}>
              {sortedObits?.length && (
                <>
                  <Grid item xs={12}>
                    <Box
                      mb={{ xs: 2, md: 2 }}
                      ml={{ xs: 2, md: 0 }}
                      nonce={undefined}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    >
                      <Text variant="h3" display={'inline'}>
                        {resultsTitle}
                      </Text>
                      <Text variant="h4" fontWeight={400} display={'inline'}>
                        {' '}
                        for{' '}
                      </Text>
                      <Text variant="h3" display={'inline'}>
                        {searchData.name}
                      </Text>
                    </Box>
                  </Grid>
                  {sortedObits.map((obituary, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <ObituaryResultsCard obituaryData={obituary} />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}
