import {
  useReport,
  ReportLoading,
  ReportNullState,
  PhoneFraudReport,
  DebugMenu,
  FraudSearchContainer,
  FraudPhoneOverview,
  FraudPhoneLocationDetails,
  FraudPhoneThreatDetection,
  PotentialOwnerCta,
  CommentsSectionV2,
  SearchFormProps,
} from '@ltvco/refresh-lib/v1';
import { getFraudSearchSubtitleText } from './utils';
import {
  SearchFormsContainer,
  DetailsContainer,
  ReportContainer,
  InformationCardContainer,
} from './components';
import { alpha, Box, Button, Divider, Stack } from '@mui/material';
import type { ReportRouteProps } from '../types';
import { useRef, useState } from 'react';
import { DashboardInformationCard } from 'components/Dashboard/DashboardInformationCard';
import { formatPhone, isZeroed } from '@ltvco/refresh-lib/utils';
import { Grid, Text } from '@ltvco/refresh-lib/theme';
import { ArrowOutward } from '@mui/icons-material';

interface PhoneFraudReportPageProps extends ReportRouteProps {
  /** Optional callback to handle successful search form submission */
  onSearchSuccess?: SearchFormProps['onSuccess'];
  /** Optional callback to handle search form submission errors */
  onSearchError?: SearchFormProps['onError'];
}

export function PhoneFraudReportPage({
  permalink,
  onSearchSuccess,
  onSearchError,
}: PhoneFraudReportPageProps) {
  // hooks
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);

  // refs
  const commentsSectionRef = useRef<HTMLDivElement | null>(null);

  // state
  const [tabState, setTabState] = useState<string>('phone');

  // constants
  const urlParams = new URLSearchParams(window.location.search);
  const fallbackNumber = urlParams.get('phone') || '';

  const scrollToComments = () => {
    if (!commentsSectionRef.current) return;
    commentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }
  const report = new PhoneFraudReport(queryResult?.data);

  if (!report) return <ReportLoading menuItems={2} />;
  if (report?.data?.isEmpty) {
    return <ReportNullState />;
  }

  const {
    carrier,
    fraudScore,
    isActive,
    isDoNotCall,
    isLeaked,
    isPrepaid,
    isRecentAbuse,
    isRisky,
    isSpammer,
    isVOIP,
    isValid,
    lineType,
    location,
    userActivity,
    activeStatus,
    number,
  } = report.data;

  const phoneNumber = number ?? fallbackNumber;
  document.title = `${formatPhone(phoneNumber)} - PeopleLooker`;

  const timeZone = location?.timezone;

  return (
    <>
      <SearchFormsContainer>
        <Text data-cy="fraud-check-form-title" variant="h2" color="success">
          FraudScan
        </Text>
        <Text
          mb={(theme) => ({ xs: theme.spacing(1), md: theme.spacing(3) })}
          data-cy="fraud-check-form-subtitle"
          variant="body2"
        >
          {getFraudSearchSubtitleText(tabState)}
        </Text>
        <FraudSearchContainer
          excludeTabs={['ip', 'url', 'email']}
          onSearchSuccess={onSearchSuccess}
          onSearchError={onSearchError}
          onSearchChange={setTabState}
        />
      </SearchFormsContainer>
      <Grid container>
        <ReportContainer
          container
          item
          xs={12}
          md={9}
          direction={'row'}
          columns={12}
        >
          <DetailsContainer mt={3}>
            <Stack
              mb={1.5}
              flexDirection={{ sm: 'row' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <Text
                data-cy="fraud-check-form-title"
                variant="h3"
                color="success"
              >
                Phone Number Verification
              </Text>
              <Button
                color="success"
                sx={(theme) => ({
                  paddingLeft: { xs: 0, md: theme.spacing(1) },
                  width: 'fit-content',
                })}
                onClick={() => scrollToComments()}
                variant="text"
              >
                <Stack direction="row" alignItems="center">
                  <Text fontWeight="bold" variant="body2">
                    Report Number
                  </Text>
                  <ArrowOutward fontSize="small" />
                </Stack>
              </Button>
            </Stack>
            <Box
              padding={1}
              mb={4}
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.success.dark, 0.04),
              })}
            >
              <Text variant="h5" component="p" fontWeight={700} m={0}>
                Results for: {formatPhone(phoneNumber)}
              </Text>
            </Box>

            <FraudPhoneOverview
              carrier={carrier}
              isVOIP={isVOIP}
              userActivity={userActivity}
              lineType={lineType}
              isPrepaid={isPrepaid}
              isLeaked={isLeaked}
              activeStatus={activeStatus}
              isValid={isValid}
              isActive={isActive}
              isDoNotCall={isDoNotCall}
            />
            <Box mt={4}>
              <PotentialOwnerCta number={phoneNumber} reportType="phone" />
            </Box>
            <Box mt={4} mb={4}>
              <FraudPhoneThreatDetection
                fraudScore={fraudScore}
                isRisky={isRisky}
                isSpammer={isSpammer}
                isRecentAbuse={isRecentAbuse}
              />
            </Box>
            <Divider />
            <Box mt={4} mb={4}>
              <FraudPhoneLocationDetails
                phoneNumber={phoneNumber}
                timeZone={timeZone}
              />
            </Box>
            <Box ref={commentsSectionRef} mt={4} mb={4}>
              <Divider />
            </Box>
            <CommentsSectionV2 permalink={permalink} reportData={report.data} />
          </DetailsContainer>
        </ReportContainer>
        <Grid xs={12} md={3} item>
          <InformationCardContainer>
            <DashboardInformationCard />
          </InformationCardContainer>
        </Grid>
      </Grid>

      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
