import {
  AccountInformation,
  GenericCtaCard,
  LimitedPlanProgress,
  PaymentsSelectionModal,
  ViewOrderHistory,
  RadioPaymentsModal,
  MyAccountHeader,
} from '@ltvco/refresh-lib/v1';
import { Grid, Box } from '@ltvco/refresh-lib/theme';
import { useEffect, useState } from 'react';
import { constants } from 'appConstants';
import {
  AccountInformationOptions,
  GenericCtaCardProps,
  editOptionClicked,
} from './BillingConstants';
import { PayPalRedirectionModal } from '@ltvco/refresh-lib/payments';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { useFeatureValue } from '@ltvco/refresh-lib/vendors';
import { AccountEditModals } from '@ltvco/refresh-lib/v2';

export function Billing({
  onCancelConfirmCancelModal,
}: {
  onCancelConfirmCancelModal?: () => void;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'My Account - PeopleLooker';
  }, []);

  const {
    isLimitedUser,
    isUnlimitedUser,
    reportLimit,
    reportsRun,
    reportsRunPercentage,
    isLoading,
  } = useLimitedPlanInfo();

  const [openPaymentsSelectionModal, setOpenPaymentsSelectionModal] =
    useState<boolean>(false);

  const [openPaymentsUpdateModal, setOpenPaymentsUpdateModal] =
    useState<boolean>(false);

  //Paypal Redirection modal
  const [openPaypalRedirection, setOpenPaypalRedirection] =
    useState<boolean>(false);
  const [paypalState, setPaypalState] = useState<'success' | 'canceled'>(
    'success'
  );
  const togglePaypalRedirectionModal = () =>
    setOpenPaypalRedirection(!openPaypalRedirection);

  const [editing, setEditing] = useState<string | null>('');

  const { ctaCardProps, length } = GenericCtaCardProps(navigate);

  const togglePaymentsSelectionModal = () =>
    setOpenPaymentsSelectionModal(!openPaymentsSelectionModal);

  const togglePaymentsUpdateModal = () => {
    setOpenPaymentsUpdateModal(!openPaymentsUpdateModal);
    if (!openPaymentsSelectionModal) {
      togglePaymentsSelectionModal();
    }
  };
  const CancelMyAccount = {
    name: 'Cancel My Account',
    display: 'Cancel My Account',
    value: 'Cancel My Account',
    constantValue: 'CANCEL_MY_ACCOUNT',
  };
  const IsinCancelVariation = useFeatureValue('OAR-1347', '');
  const isInCancelMenuVariation_3 =
    IsinCancelVariation === 'OAR-1347_variation_3';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('bvppcanc')) {
      setPaypalState('canceled');
      setOpenPaypalRedirection(true);
      return;
    }
    if (urlParams.get('bvpp') && urlParams.get('token')) {
      setPaypalState('success');
      setOpenPaypalRedirection(true);
    }
  }, []);

  useEffect(() => {
    if (isInCancelMenuVariation_3) {
      const exist = AccountInformationOptions.find(
        (option) => option.value === 'Cancel My Account'
      );
      if (!exist) AccountInformationOptions.push(CancelMyAccount);
    }
  }, [isInCancelMenuVariation_3]);

  const finishEditing = () => {
    setEditing('');
  };

  return (
    <>
      <MyAccountHeader isUnlimited={isUnlimitedUser} />
      {isLimitedUser && !isLoading && (
        <LimitedPlanProgress
          plansWithout5ReportBlocker={constants.data.plansWithout5ReportBlocker}
          validReportCountThreshold={constants.data.validReportCountThreshold}
          progressValue={reportsRunPercentage}
          reportsLimit={reportLimit}
          reportsRun={reportsRun}
          link="/upgrade/plan"
          blockUpgradeWindowThreshold={
            constants.data.blockUpgradeWindowThreshold
          }
        />
      )}
      <Grid container spacing={2} justifyContent="center">
        {[...Array(length)].map((_x, index) => (
          <Grid item xs={12} md={6} key={index}>
            <GenericCtaCard {...ctaCardProps[index]}></GenericCtaCard>
          </Grid>
        ))}
      </Grid>

      <AccountEditModals
        editing={editing}
        finishEditing={finishEditing}
        onCancelConfirmCancelModal={onCancelConfirmCancelModal}
      />
      <Box
        mt={2.5}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <AccountInformation
          editOptions={AccountInformationOptions}
          editOptionClick={(value: string | null) => {
            editOptionClicked(value, setOpenPaymentsSelectionModal, setEditing);
          }}
        />
      </Box>
      <ViewOrderHistory />
      <PaymentsSelectionModal
        open={openPaymentsSelectionModal}
        onClose={togglePaymentsSelectionModal}
        onAddPaymentMethod={() => {
          setOpenPaymentsSelectionModal(false);
          setOpenPaymentsUpdateModal(true);
        }}
      />
      <RadioPaymentsModal
        isOpen={openPaymentsUpdateModal}
        onCloseHandle={togglePaymentsUpdateModal}
      />
      <PayPalRedirectionModal
        paypalState={paypalState}
        isOpen={openPaypalRedirection}
        onCloseHandle={togglePaypalRedirectionModal}
      />
    </>
  );
}
