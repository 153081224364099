import { RightRailCard } from '@ltvco/refresh-lib/v1';
import { Grid } from '@ltvco/refresh-lib/theme';

import imgDashboardCard from '../../images/img_dashboard_card.svg';

export function DashboardInformationCard() {
  return (
    <RightRailCard
      title="Who Called Me From This Phone Number?"
      body="Receiving calls from an unknown number can be frustrating. Look up the number that’s calling you now and we may have information on who's calling."
    >
      <Grid item>
        <img src={imgDashboardCard} alt="Dashboard Card" width="100%" />
      </Grid>
    </RightRailCard>
  );
}
