import { SearchComponent, generateQueryConfig } from '@ltvco/refresh-lib/v1';
import { useQueryParams } from 'use-query-params';
import { useEffect, useState } from 'react';
import { useDebounce } from '@ltvco/refresh-lib/utils';

const queryConfig = generateQueryConfig();

export const Search = () => {
  const [queryParams, setQueryParam] = useQueryParams(queryConfig);
  const [searchBy, setSearchBy] = useState(queryParams.search_by);

  const updateUrlState = () => setQueryParam({ search_by: searchBy });
  const debouncedUpdateUrlState = useDebounce(updateUrlState, 2000, [searchBy]);
  useEffect(() => debouncedUpdateUrlState(), [debouncedUpdateUrlState]);

  // For handling the 'Enter' button case in the input
  const cancelDebounceAndUpdate = () => {
    debouncedUpdateUrlState.cancel();
    updateUrlState();
  };

  return (
    <SearchComponent
      searchBy={searchBy}
      setSearchBy={setSearchBy}
      cancelDebounceAndUpdate={cancelDebounceAndUpdate}
    />
  );
};
