import { Box, styled, Stack } from '@ltvco/refresh-lib/theme';
import { SsoPromoSideBar } from '@ltvco/refresh-lib/v1';
import { SideBarMigrationCta } from '@ltvco/refresh-lib/v2';
import { Sidenav } from 'components/sidenav/Sidenav';

const StyledContent = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  minHeight: '80vh',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('md')]: {
    maxWidth: '1296px',
    width: 'calc(100% - 250px)',
    padding: theme.spacing(3.5),
  },
}));

export function SidenavContainer({
  children,
  defaultBackground = false,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultBackground?: boolean;
}) {
  return (
    <Stack direction="row">
      <Box
        display={{ xs: 'none', md: 'block' }}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderColor: theme.palette.divider,
        })}
      >
        <Box position={'sticky'} top={55}>
          <Sidenav />
          <SsoPromoSideBar />
          <SideBarMigrationCta />
        </Box>
      </Box>
      <StyledContent>{children}</StyledContent>
    </Stack>
  );
}
