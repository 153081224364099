import { useEffect, useState } from 'react';
import { useQueryParams } from 'utils/useQueryParams';
import {
  PropertyOverview,
  LocationOfProperty,
  LotAndBuildingDetails,
  useReport,
  AssessedValue,
  BuildingPermits,
  HomeOwnerAssociationSection,
  DeedsSection,
  LiensSection,
  EstimatedValue,
  PropertyReport,
  DesktopNav,
  NotesSection,
  PdfSection,
  NaturalHazards,
  ReportLoading,
  PossibleOwners,
  PossibleResidents,
  ReportNavMobile,
  OwnershipTimeline,
  PropertyResults,
  CountyAssessorRecords,
  ReportNullState,
  EquityAndLoans,
  MarketInsights,
  buildForSaleTab,
  buildPreForeclosureTab,
  peopleTypes,
  MonitoringBanner,
  Demographics,
  Schools,
  useReportMonitors,
  useTuxedo,
  useSession,
  EditPropertyDetailsModal,
  useRemouladeReportSnapshot,
  ReportChangesOverview,
  DebugMenu,
} from '@ltvco/refresh-lib/v1';

import type { ReportRouteProps } from './types';
import {
  groupAvmsBySource,
  getPropertyDetailsCount,
  getPropertyValuesCount,
} from 'utils/propertyReportUtils';
import { getPropertyNavLinkData } from 'navLinkData/propertyNavLinkData';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DateUtil,
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { ReportContextProvider } from '@ltvco/refresh-lib/ctx';
import { Grid, Box } from '@ltvco/refresh-lib/theme';
import { ReportActionsWithDateUpdated } from '@ltvco/refresh-lib/v2';

interface PropertyReportProps extends ReportRouteProps {}

export function PropertyReportPage({
  permalink,
  isMonitored = false,
}: PropertyReportProps) {
  const queryParams = useQueryParams();

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const queryReportResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;
  const navigate = useNavigate();

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroedReport = isZeroed(queryReportResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryReportResult);

  const fullAddress = generateFullAddressFromParams(queryParams);
  const firstProperty = queryReportResult?.data?.entities?.properties?.[0];

  const { reportIsMonitored } = useReportMonitors();

  const {
    userProfile,
    homeDataSet,
    homeAdditions,
    setupUserProfile,
    createUserProfile,
  } = useTuxedo();
  const { session } = useSession();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const postHomeData = homeDataSet;
  const [temporaryPermalink, setTemporaryPermalink] = useState<string | null>(
    null
  );
  const [hasTriggeredMutation, setHasTriggeredMutation] = useState(false);
  const [userProfileTuxedo, setUserProfileTuxedo] = useState(null);
  const tuxedoBuilding = homeAdditions(temporaryPermalink) || { data: null };
  const [readyToFetch, setReadyToFetch] = useState(false);
  useEffect(() => {
    if (!firstProperty || hasTriggeredMutation) return;

    if (!readyToFetch) {
      setReadyToFetch(true);
    }
  }, [firstProperty, hasTriggeredMutation]);

  // Handling the user profile creation and query success
  useEffect(() => {
    if (readyToFetch) {
      if (userProfile.isError && !hasTriggeredMutation) {
        if (
          !userProfile?.data?.user_profile &&
          session?.account?.account?.user_info
        ) {
          const userProfileData = setupUserProfile(
            session.account.account.user_info,
            firstProperty.mailing_address
          );

          userProfileData.record_search = {
            address: firstProperty?.parcel_address?.full,
            city: firstProperty?.parcel_address?.parsed?.city,
            state: firstProperty?.parcel_address?.parsed?.state,
            zip_code: firstProperty?.parcel_address?.parsed?.zip5,
          };

          createUserProfile.mutate({
            userProfile: {
              user_profile: userProfileData,
              record_search: userProfileData.record_search,
            },
          });

          setHasTriggeredMutation(true);
        }
      }

      if (userProfile.isSuccess && userProfile.data?.user_profile) {
        setTemporaryPermalink(permalink);
        setUserProfileTuxedo(userProfile.data.user_profile);
      }
    }
  }, [readyToFetch, userProfile, session, firstProperty, createUserProfile]);

  // Update state when userProfile fetch succeeds
  useEffect(() => {
    if (userProfile.isSuccess && userProfile.data?.user_profile) {
      setTemporaryPermalink(permalink);
      setUserProfileTuxedo(userProfile.data.user_profile);
    }
  }, [userProfile, permalink]);

  if (queryReportResult.isLoading || queryReportResult.isError) {
    return <ReportLoading menuItems={10} />;
  }

  if (zeroedReport) {
    return <ReportNullState />;
  }

  const handleOpenEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const report = new PropertyReport(queryReportResult?.data);

  if (!report) return <ReportLoading menuItems={10} />;

  const property = report.data.properties[0];
  const responseHasPropertyDataAndNoEntitiesFlagIsNull =
    (report.data?.meta?.counts?.properties ?? 0) > 0 &&
    queryReportResult?.data?.meta?.no_entities === null;
  const noEntitiesFlagIsEitherTrueOrNull =
    queryReportResult?.data?.meta?.no_entities ||
    queryReportResult?.data?.meta?.no_entities === null;

  if (
    !responseHasPropertyDataAndNoEntitiesFlagIsNull &&
    noEntitiesFlagIsEitherTrueOrNull
  ) {
    const options = queryReportResult?.data?.entities?.options;
    const fromParam = queryParams.get('from') || '';
    return (
      <ReportContextProvider permalink={permalink} reportType="property">
        <Grid
          sx={{
            bgcolor: 'background.default',
            flexDirection: 'column',
            paddingBottom: '20px',
            px: 2,
          }}
          container
        >
          <PropertyResults
            searchAddress={report?.data?.address || fullAddress}
            options={options}
            fromDashboard={fromParam}
          />
        </Grid>
      </ReportContextProvider>
    );
  }

  const userProfileForEditModal = userProfileTuxedo
    ? userProfileTuxedo
    : setupUserProfile(
        session.account?.account?.user_info,
        property?.mailing_address
      );
  let updatedBuilding = property?.buildings?.[0];

  if (
    userProfileTuxedo &&
    tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
  ) {
    const totalRoomsValue =
      tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
        ?.custom_attributes?.beds +
      tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
        ?.custom_attributes?.baths;

    const updatedBuildingTuxedo = {
      ...updatedBuilding,
      rooms: {
        ...updatedBuilding?.rooms,
        bed: tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
          ?.custom_attributes?.beds,
        baths: {
          ...updatedBuilding?.rooms?.baths,
          total:
            tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
              ?.custom_attributes?.baths,
        },
        total: totalRoomsValue,
      },
      area: tuxedoBuilding?.data?.user_data_sets?.user_property_detail_data_set
        ?.custom_attributes?.sqft,
    };
    updatedBuilding = updatedBuildingTuxedo;
  }

  const displayAddress = report.data.address || fullAddress;

  const marketValues = property.market;
  const marketSortedBySource = groupAvmsBySource(marketValues);
  const propertyDetailsCount = getPropertyDetailsCount(property);
  const PropertyValuesCount = getPropertyValuesCount(property);
  const propertyNavLinkData = getPropertyNavLinkData(
    property?.owners.length,
    property?.residents.length,
    property?.building_permits ? property?.building_permits.length : 0,
    property?.deeds.length,
    property?.courts.civils.length,
    propertyDetailsCount || 0,
    PropertyValuesCount || 0,
    report?.data?.properties?.[0]?.equities?.length || 0,
    report?.data?.schools?.length || 0
  );

  const listingsNavigateAction = (property: any, type?: string) => {
    const { city, state, zip5 } = property?.parcel_address?.parsed || {
      city: '',
      state: '',
      zip5: '',
    };
    const url = `/search/listing?city=${city}&state=${state}&zip5=${zip5}&search_type=${
      type || ''
    }`;
    navigate(url);
  };

  const marketInsightsTabs = [
    buildForSaleTab({
      property,
      data: report?.data?.listings?.slice(0, 10) || [],
      navigateAction: listingsNavigateAction,
      shouldUseModal: true,
    }),
    buildPreForeclosureTab({
      property,
      data: report?.data?.preforeclosures?.slice(0, 10) || [],
      navigateAction: listingsNavigateAction,
      shouldUseModal: true,
    }),
  ];

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';
  document.title = `${displayAddress} - PeopleLooker`;

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <DesktopNav
            navLinkData={propertyNavLinkData}
            reportDataPoint={displayAddress}
            permalink={permalink}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={8}
          lg={8}
          marginTop={{ xs: 0, md: 0.5 }}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: { xs: 3.75, md: 7 },
            },
          }}
        >
          {reportIsMonitored && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={propertyNavLinkData}
            />
          )}
          <>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                marginX: -1,
                top: 52,
                zIndex: 10,
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                },
              }}
            />

            <ReportActionsWithDateUpdated
              reportType={'property'}
              reportTitle="Property"
              dateUpdated={reportUpdateDate}
              reportUpgraded={false}
              shouldFireGAUpsell
            />
          </>
          <PropertyOverview
            propertyReportData={property}
            permalink={permalink}
            canEditProperty={true}
            updatedBuilding={updatedBuilding}
            setIsEditModalOpen={handleOpenEditModal}
            useStreetViewToggle={true}
            marketValues={marketValues}
          />
          <MonitoringBanner
            propertyReportData={property}
            permalink={permalink}
            reportType="property"
          />
          <PossibleOwners
            owners={property.owners as peopleTypes.Identities}
            propertyAddress={displayAddress}
            permalink={permalink}
          />
          <OwnershipTimeline
            owners={property.owners as peopleTypes.Identities}
            deeds={property.deeds}
            propertyAddress={displayAddress}
            permalink={permalink}
          />
          <PossibleResidents
            owners={property.owners}
            residents={property.residents}
            propertyAddress={displayAddress}
            permalink={permalink}
          />
          <LotAndBuildingDetails
            propertyAddress={displayAddress}
            property={property}
            canEditProperty={true}
            updatedBuilding={updatedBuilding}
            setIsEditModalOpen={handleOpenEditModal}
            permalink={permalink}
          />
          <LocationOfProperty
            propertyAddress={displayAddress}
            property={property}
            permalink={permalink}
          />
          <HomeOwnerAssociationSection
            propertyAddress={displayAddress}
            hoas={property.hoas}
          />
          <AssessedValue
            propertyAddress={displayAddress}
            property={property}
            permalink={permalink}
          />
          <CountyAssessorRecords
            propertyReportData={property}
            permalink={permalink}
          />
          <BuildingPermits
            propertyAddress={displayAddress}
            property={property}
            permalink={permalink}
          />
          <DeedsSection
            propertyAddress={displayAddress}
            deeds={property.deeds}
            permalink={permalink}
          />
          <LiensSection
            propertyAddress={displayAddress}
            liens={property.courts}
            permalink={permalink}
          />
          <EstimatedValue
            property={property}
            propertyAddress={displayAddress}
            marketValues={marketValues}
            rentalValues={property.values?.rental || []}
            avmsPartners={marketSortedBySource}
            permalink={permalink}
          />
          <EquityAndLoans
            propertyAddress={displayAddress}
            property={report.data}
            permalink={permalink}
          />
          <MarketInsights
            propertyAddress={displayAddress}
            tabs={marketInsightsTabs.filter((item) => !!item)}
          />
          <Schools propertyAddress={displayAddress} property={report.data} />
          <Demographics property={property} propertyAddress={displayAddress} />
          {/* <HomesNearby />
          <SurroundingArea /> */}
          <NaturalHazards
            propertyAddress={displayAddress}
            property={property}
          />
          <PdfSection permalink={permalink} shouldFireGAUpsell />
          <NotesSection permalink={permalink} />
          {/* <Comments /> */}
          <EditPropertyDetailsModal
            open={isEditModalOpen}
            setIsOpen={handleOpenEditModal}
            building={updatedBuilding}
            permalink={permalink}
            userProfile={userProfileForEditModal}
            postHomeData={postHomeData}
            propertyAddress={displayAddress}
          />
        </Grid>
      </Grid>
      <ReportNavMobile navLinkData={propertyNavLinkData} />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}

function generateFullAddressFromParams(queryParams: URLSearchParams): string {
  const address = queryParams.get('address') || '';
  const city = queryParams.get('city') || '';
  const state = queryParams.get('state') || '';
  const zipcode = queryParams.get('zipcode') || '';

  return `${address} ${city}, ${state} ${zipcode}`;
}

// function HomesNearby() {
//   return <PlaceholderSection title={'Homes Nearby'} />;
// }

// function SurroundingArea() {
//   return <PlaceholderSection title={'Surrounding Area'} />;
// }
