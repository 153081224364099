import {
  useSearchPerson,
  ResultFilter,
  ResultCard,
  PersonSearchLoading,
  PersonSearchNoResults,
  Record,
} from '@ltvco/refresh-lib/v1';
import { Stack, Text, Grid, Box, styled } from '@ltvco/refresh-lib/theme';
import { titleize } from '@ltvco/refresh-lib/utils';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const StickyContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 60,
  width: '100%',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: theme.spacing(10),
    zIndex: 1,
    marginBottom: theme.spacing(3),
    height: 'unset',
    overflowY: 'unset',
  },
}));

export function SearchPersonResults() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = 'Search - PeopleLooker';
  }, []);

  let searchData = {
    fName: searchParams.get('fname') || '',
    mName: searchParams.get('mn'),
    lName: searchParams.get('ln') || '',
    state:
      searchParams.get('state') === 'All' ? 'All' : searchParams.get('state'),
    city: searchParams.get('city'),
    age: searchParams.get('age'),
  };

  const SearchPersonQuery = {
    searchPersonBy: 'name',
    options: {
      personSearchParams: searchData,
      // TODO: Once the OptOut implementation starts, improve this
      isOptout: false,
    },
  };

  let { data: searchPersonResult = [], isFetched } =
    useSearchPerson(SearchPersonQuery);
  searchPersonResult = searchPersonResult as Record[];
  const exactMatchCount = searchPersonResult.filter(
    (person: Record) => person.nameMatched
  ).length;

  const getFullName = () => {
    const { fName, mName, lName } = searchData;

    const nameData = [fName, mName, lName].filter(Boolean);
    const fullName = nameData.join(' ');

    return titleize(fullName.toLowerCase());
  };

  // TODO: implement or add pluralize for general usage
  const resultsTitle = exactMatchCount
    ? `${exactMatchCount} result${exactMatchCount > 1 ? 's' : ''}`
    : 'No exact match';

  return (
    <>
      {!isFetched ? (
        <PersonSearchLoading />
      ) : (
        <Stack sx={{ minHeight: 800, position: 'relative' }}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={3}>
              <StickyContainer>
                <ResultFilter personInfo={searchData} />
              </StickyContainer>
            </Grid>
            <Grid item xs={12} md={9} sx={{ mt: { xs: 8.75, md: 0 } }}>
              {searchPersonResult.length ? (
                <>
                  <Grid item xs={12}>
                    <Box
                      mb={{ xs: 2, md: 2 }}
                      ml={{ xs: 2, md: 0 }}
                      nonce={undefined}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    >
                      <Text variant="h3" display={'inline'}>
                        {resultsTitle}
                      </Text>
                      <Text variant="h4" fontWeight={400} display={'inline'}>
                        {' '}
                        for{' '}
                      </Text>
                      <Text variant="h3" display={'inline'}>
                        {getFullName()}
                      </Text>
                    </Box>
                  </Grid>
                  {searchPersonResult.map((person: Record, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <ResultCard {...person} />
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <PersonSearchNoResults />
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}
