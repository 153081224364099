import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { Box } from '@ltvco/refresh-lib/theme';
import { SideNavigationMenu } from '@ltvco/refresh-lib/v2';
import { useContext } from 'react';

export function Sidenav({ onReport }: { onReport?: boolean }) {
  const {
    config: { sideMenuItems },
  } = useContext(AppConstants);

  const newDesignFilteredSideMenuItems = sideMenuItems.filter(
    (item) => !(item.name === 'logout')
  );

  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
        '>.MuiList-root': {
          width: '310px',
          maxWidth: '310px',
        },
      }}
    >
      <SideNavigationMenu
        menuItems={newDesignFilteredSideMenuItems}
        matchingRoute={location.pathname}
        onReport={onReport}
        hideItems={[
          { name: 'myGarage', routesToHide: ['dashboard', 'report'] },
          { name: 'myAccount', routesToHide: ['report'] },
        ]}
      />
    </Box>
  );
}
