import { useContext } from 'react';
import {
  useReport,
  ReportLoading,
  FullObituary,
  ObituaryHeader,
  ObituaryGeneralInformation,
  ReportNullState,
  ObituaryReport,
  DebugMenu,
  PersonSearchCta,
} from '@ltvco/refresh-lib/v1';
import type { ReportRouteProps } from './types';
import { useLocation } from 'react-router-dom';
import { constants } from 'appConstants';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { Card, Grid } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface ObituaryReportProps extends ReportRouteProps {}

export function ObituaryReportPage({ permalink }: ObituaryReportProps) {
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const { trackEvent } = useContext(AppConfig);

  trackEvent('view full report', 'view', 'obituary search');

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = new ObituaryReport(queryResult?.data);

  if (!report) return <ReportLoading menuItems={2} />;

  if (!report?.data?.obituaries && !report?.data?.obituaries?.length) {
    return <ReportNullState />;
  }

  const obituary = report.data.obituaries[0];

  const {
    name,
    dob,
    dod,
    funeralHome,
    funeralHomeAddress,
    funeralHomeUrl,
    message,
    photoUrl,
    nickname,
    personUrl,
    recentCity,
    recentRegion,
  } = obituary;

  const locationValue =
    recentCity && recentRegion ? `${recentCity}, ${recentRegion}` : null;

  return (
    <>
      <Grid container direction={'row'} columns={12}>
        <Grid item sm={12} marginTop={3}>
          <Card id="obituary-overview" sx={{ boxShadow: 'none' }}>
            <ObituaryHeader
              personName={name}
              nickname={nickname}
              personImage={photoUrl}
              age={''}
              dob={dob}
              dod={dod}
              funeralHome={funeralHome}
              funeralHomeAddress={funeralHomeAddress}
              funeralHomeUrl={funeralHomeUrl}
              photoUrl={photoUrl}
              personUrl={personUrl}
            />
            <Grid item sm={8} margin="auto">
              <ObituaryGeneralInformation
                dob={dob}
                dod={dod}
                placeOfBirth={null}
                location={locationValue}
                imgInfinity={constants.images.imgInfinity}
              />
            </Grid>
          </Card>
          <Grid item sm={8} margin="auto">
            <FullObituary text={message} personName={name} />
            <PersonSearchCta
              image={constants.images.imgPersonSearch}
              personName={name}
            />
          </Grid>
        </Grid>
      </Grid>
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
